@import '../../../assets/sass/palette.scss';
.helper-text {
    color: $gray-border-color;
    font-size: 14px;
    line-height: 21px;
}
.warning-text {
    color: $primary-red;
}
.input-title-section {
    align-items: baseline;

    .input-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1e2749;
        font-family: 'poppins-medium';        
    }

    span:not(.svg-icon) {
        line-height: 18px;
    }

    .change-input-text {
        right: 0;
        top: -2px;
    }
}
.dropdown-box {
    input {
        border: none !important;

        &:focus {
            outline: none !important;
        }
    }
}
.no-padding {
    input {
        padding: 0 !important;
    }
}
.input-box {
    input {
        border-radius: 7px;
        border: none;
        font-size: 14px;
        font-weight: 400;
        font-family: 'poppins-regular' !important;
        color: #1e2749;

        &.input-error {
            outline: 1px solid red;
        }

        &:not([readonly]):focus {
            outline: 1px solid #00a3ff;
        }

        &::placeholder {
            color: #a1a5b7;
        }
    }

    &.left-border-flat {        
        input {
            border-left: none !important;
            border-radius: 0 7px 7px 0;

            &:not([readonly]):focus {
                outline: 1px solid #00a3ff;
            }
        }
    }

    .input-icon {
        top: 13px;
        left: 15px;
    }

    .password-icon {
        margin-top: 6px;
        margin-right: 13.5px;
        right: 0;
    }
}

.text-error {
    color: #f1416c;
}
