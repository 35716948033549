.data-table-header {
  position: sticky;
  top: 0px;
  z-index: 12;  
  height: 70px;
  background-color: #fff;
  border-radius: 10px 10px 0 0;

  &.sticky {    
    border-bottom: 1px solid #e9e9eb !important;      
    border-radius: 0;  
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}

.ReactVirtualized__Table__headerColumn {  
  font-size: 14px;
  line-height: 22px;
  color: #1e2749;  
  font-weight: 600;
  margin: 0;
  padding: 0 10px;

  &:last-child {
    padding: 0;
  }

  &:not(.fixed-width-column) {
    flex: 1 1 0 !important;
  }  
}

.ReactVirtualized__Table__row {
  border-top: 1px solid #e9e9eb !important;

  &:hover {
    background: #f8f8fa;
  }
}

.ReactVirtualized__Table__rowColumn {
  margin: 0;
  padding: 0 10px;

  &:last-child {
    padding: 0;
  }

  &:not(.fixed-width-column) {
    flex: 1 1 0 !important;
  }  
}

.table-sticky-scroll {
  height: 8px;
  position: sticky;
  bottom: 0;
  height: 8px !important;
  z-index: 3;
  display: flex;
  align-items: center;
  background: rgba(5, 5, 5, 0.06);
  border-top: 1px solid #f0f0f0;
  opacity: 0.65;  

  &.hidden-scrollbar {
    background: transparent;

    .table-sticky-scroll-bar {
      height: 0;
    }
  }

  &-bar {
    height: 8px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    transition: all 0.3s, transform none;
    position: absolute;
    bottom: 0;    
    cursor: pointer;

    &.active-scrollbar, &:hover {
      background-color: rgba(0, 0, 0, 0.88);
    }    
  }
}

.detail-text-section-block {
  min-width: 200px;
}

.ReactVirtualized__Table {
  overflow: visible;
}