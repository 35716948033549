.product-list-table {
  height: calc(100vh - 552px);  
  overflow-y: scroll;
  margin-right: -13px;

  @media screen and (max-width: 991px) {
    height: calc(100vh - calc(var(--ZOOM-MULTIPLIER) * 35rem));
  }

  &-header {
    padding: calc(var(--ZOOM-MULTIPLIER) * 0.71rem) calc(var(--ZOOM-MULTIPLIER) * 1rem);
    background-color: #f8f8fa;
    border-radius: calc(var(--ZOOM-MULTIPLIER) * 0.71rem) calc(var(--ZOOM-MULTIPLIER) * 0.71rem) 0 0;
    top: 0;
    z-index: 10;  

    .left-section {
      width: 50%;    
    }

    .right-section {
      width: 47%;
    }

    span {
      font-size: calc(var(--ZOOM-MULTIPLIER) * 0.86rem);
      font-weight: 600;
      line-height: calc(var(--ZOOM-MULTIPLIER) * 1.57rem);
      color: #4a516c;
    }
  }
}

.empty-product-list {
  background-color: #f8f8fa;
  border-radius: calc(var(--ZOOM-MULTIPLIER) * 0.71rem);
  height: calc(100vh - 552px);  

  .m-0 {
    margin-left: 0 !important;
  }
  @media screen and (max-width: 991px) {
    height: calc(var(--ZOOM-MULTIPLIER) * 25rem);
  }

  span {
    font-weight: 600;    
    color: #4b526d;
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .no-select-text-product-in-list {
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;    
    color: #a1a5b7;
  }

  // .add-product-btn {
  //   font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem) !important;
  //   padding: calc(var(--ZOOM-MULTIPLIER) * 0.5rem) calc(var(--ZOOM-MULTIPLIER) * 1rem) !important;
  // }

  img {
    width: 6rem;
  }
}

.product-list-table-header {
  z-index: 1;
  th {
    font-size: calc(var(--ZOOM-MULTIPLIER) * 0.86rem);
    font-weight: 600;
    line-height: calc(var(--ZOOM-MULTIPLIER) * 1.57rem);
    color: #4a516c;
    padding-right: calc(var(--ZOOM-MULTIPLIER) * 0.3rem);
    &.th-product-main {
      padding: calc(var(--ZOOM-MULTIPLIER) * 0.71rem) calc(var(--ZOOM-MULTIPLIER) * 1rem);
    }
    &.blank {
      padding: 0;
    }
  }
}

.product-list-product-detail {
  position: relative;
  padding: calc(var(--ZOOM-MULTIPLIER) * 1rem);
  border-bottom: 1px solid #e9e9eb;
  width: 100%;
  background-color: #fff;

   &.visited {
     background-color: #f9fafa;
   }

  .product-list-product-main {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: calc(var(--ZOOM-MULTIPLIER) * 1rem);
  }

  &.low-stock {
    background-color: #ffebeb !important;
  }

  &.active {
    background-color: #dbeffb;
    border-bottom: 1px solid #b3e3ff !important;
  }

  &:hover {
    background-color: #f8f8fa;
  }

  .left-section {

    .product-image-container {
      margin-right: calc(var(--ZOOM-MULTIPLIER) * 0.86rem);
      img {
        border-radius: 5px;
      }
    }
    .product-name-price-container {
      span {
        // font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
        // line-height: calc(var(--ZOOM-MULTIPLIER) * 1.57rem);        
        font-weight: 500;        
        font-size: 14px;        
        line-height: 22px;
        letter-spacing: 0px;        
      }

      a {
        color: #1e2749 !important;

        &:hover {
          text-decoration: underline !important;
        }
      }

      .product-name {        
        text-overflow: ellipsis;
        overflow: hidden;
        // white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .product-price {
        font-size: calc(var(--ZOOM-MULTIPLIER) * 1rem);
        font-weight: 400;
        line-height: calc(var(--ZOOM-MULTIPLIER) * 1.5rem);
      }

      .product-reference-id {
        padding: 0 calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
        background-color: #00a3ff1f;
        border-radius: 7px;
        margin-left: calc(var(--ZOOM-MULTIPLIER) * 0.5rem);
        span {
          color: #00a3ff;
          font-size: calc(var(--ZOOM-MULTIPLIER) * 0.86rem);
          font-weight: 400;
          line-height: calc(var(--ZOOM-MULTIPLIER) * 1rem);
        }
      }
    }
  }

  .right-section {
    width: 47%;

    .value {
      width: 25%;

      span {        
        // font-size: calc(var(--ZOOM-MULTIPLIER) * 0.86rem);
        // line-height: calc(var(--ZOOM-MULTIPLIER) * 1.28rem);        
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;        
      }
    }
  }
}



.live-product-item-more {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translate(0%, -50%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;

  .svg-icon {
    border-radius: 8px;
    padding: 1px;
    &:hover {
      background: #00a3ff1f;
    }
  }
  .opacity-0 {
    opacity: 0;
  }
}

.live-product-menu-dropdown {
  width: 11.42rem;
}

// ::-webkit-scrollbar-track {
//   background: transparent;
// }
// ::-webkit-scrollbar-thumb {
//   background-color: rgba(155, 155, 155, 0.5);
//   border-radius: 20px;
//   border: transparent;
//   width: 0.15rem;
// }

.expand-icon {
  &.rotate-icon {    
    svg {
      transform: rotate(90deg);
    }
  }
}
.live-product-list-container {
  height: calc(100vh - 552px);
  overflow-y: scroll;
}
#LiveProductList {
  position: relative;
}