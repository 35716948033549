.dropdown-item-red {
    color: #f1416c !important;
}
.dropdown-title {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: 'poppins-medium';
}
.dropdown-item {
    // margin: 5px;
    // width: inherit;
    .sort-arrow-div {
        border-radius: 5px;
        padding: 5px;
    }
    .sort-arrow-div:hover {
        background: rgba(0, 163, 255, 0.1);
    }

    &.menu-link {
        color: #4A516C !important;
        font-size: 14px;

        &.text-primary {
            color: #00a3ff !important;
        }

        &.text-danger {
            color: #F1416C !important;
        }
    }
}

.no-hover:hover,
.no-hover:active {
    background: white !important;
}

.dropdown-item:hover {
    background: #f8f8fa;
    .sort-arrow-div {
        background: rgba(0, 163, 255, 0.1);
    }    
}

.dropdown-item {
    &.text-danger:hover {
        background-color: #F1416C !important;
        color: #fff !important;
    }
}

.dropdown-item:active {
    background: #eaeaed;
}

.dropdown-box:not(.read-only):active,
.dropdown-box-active:not(.read-only) {
    border: 1px solid #00a3ff !important;
}

.dropdown-box-error {
    border: 1px solid #f1416c !important;
}

.dropdown-box {
    &.input-error {
        border: 1px solid #f1416c !important;
    }
}
