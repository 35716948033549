.toast-float {
    position: fixed;
    z-index: 9999;
    right: 70px;
}
.toast-modal {
    position: fixed;
    z-index: 9999;
    top: 30px;
    right: 30px;
}
.Toastify__toast {
    min-width: 350px;

    &-container--top-right {
        top: 20px;
    }
}
.toast-description {
    max-width: 350px;
}
.download-recording-toast {
    padding: 10px 0 5px 10px;

    h5 {                
        font-size: 20px;        
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 13.5px;
    }

    .progressbar-section {
        width: 100%;

        .download-progressbar {
            background-color: #f8f8fa;    
            border-radius: 20px;
        }
    }    
}
